import React from 'react'
import bridgeImg from '../images/new ripplego icons/bridge_green.png'
import msiImg from '../images/new ripplego icons/msi.png'
import lockImg from '../images/new ripplego icons/lock_gray.png'
import riverImg from '../images/new ripplego icons/water_red.png'

const icons = (color, clicked) => ({
    BRIDGE: (
        <img src={bridgeImg} style={{width:'40px'}}/>
    ),
    LOCK: (
        <img src={lockImg} style={{width:'40px'}}/>
    ),
    RIVER: (
        <img src={riverImg} style={{width:'40px'}}/>
    ),
    MSI: (
        <img src={msiImg} style={{width:'40px'}}/>
    )
})
const ripplegoIcons = ({
    color,
    type,
    onClick,
    clicked,
    onMouseEnter,
    onMouseLeave
  }) => {
    const icon = icons(
      color,
      clicked
    )[type];
    return (
      <span style={{cursor: 'pointer', border: 'none' }} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {icon}
      </span>
    );
  };
  
  export default ripplegoIcons;
