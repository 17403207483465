import * as React from "react"
import { useState } from "react"
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'gatsby'
import GlobalJSON from "../content/global.json"
import RippleGoParallex from "../images/person.png"
import Layout from "../components/layout"
import Seo from "../components/seo"
import scrollTo from 'gatsby-plugin-smoothscroll';
import KeyFeaturesFullWidth from "../components/keyFeaturesFullWidth"
import KeyFeaturesMobileWidth from "../components/keyFeaturesMobileWidth"
import "../styles/bootstrap.scss"
import "animate.css/animate.min.css";

const DEFAULT_WINDOW_SIZE = 1500
const breakPoint = 1400
const mobileBreakPoint = 815

export default function Index() {
    const [windowWidth, setWindowWidth] = useState(DEFAULT_WINDOW_SIZE)

    React.useEffect(() => {
        if(typeof window === 'undefined' || !window.document) return

        window.addEventListener('resize', () => setWindowWidth(window.innerWidth))
    }, [])

    return (
        <Layout>
            <Seo title="Home" />
            <img
                className="parallax home"
                src={RippleGoParallex}
                alt="Parallax"
            />
            <div className="banner-cover home" />
            <div className={"d-flex justify-content-center align-items-center " + (windowWidth < breakPoint ? 'px-0 mx-0' : 'mx-5 px-5')} style={{height: "calc(100vh - 61px)"}}>
                    <Row className="justify-content-around w-100 mx-5">
                        <Col className="d-flex align-items-center mb-3" style={{maxWidth: 800, minWidth: 300}}>
                            <div>
                                <span className="text-white display-6" style={{fontWeight: '500'}}>{GlobalJSON.ripplegoSubSlogan}</span>
                                <p className="mt-4 text-white"><span><i><b>RippleGo</b></i></span> is a new software application that
                                    uses predictive analytics to provide accurate
                                    and timely alerts on river level conditions,
                                    navigation hazards, lock status changes and
                                    bridge air gap clearance.
                                </p>
                                <p className="text-white">Each time something changes, <span><i><b>RippleGo </b></i></span>
                                    automatically alerts you and recalculates your
                                    ETA to destination, so you can get the most
                                    out of every voyage.
                                </p>
                            </div>
                        </Col>
                        <Col className="" style={{maxWidth: 1000, minWidth: 400}}>
                            <h5 className="text-white" >RippleGo Demo</h5>
                            <div className="ripplego-video">
                                <iframe 
                                    width="1904" 
                                    height="775" 
                                    src="https://www.youtube.com/embed/ihvlHUVBmwM" 
                                    title="Trabus Technologies RippleGo Demo Video" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </Col>
                    </Row>
            </div>
            {windowWidth >= mobileBreakPoint && <FontAwesomeIcon icon={faAngleDoubleDown} id="down-arrow" onClick={() => scrollTo('#content')}/>}
            
            {/* Key Features */}
            <Container fluid id="box3" className="px-0 position-relative key-features" style={{backgroundColor: 'white', position: "relative"}}>                       
                <Container fluid className="my-0 text-center container-padding" style={{paddingBottom: '9rem'}}>
                    <h5 className={"pt-4 text-dark " + (windowWidth < mobileBreakPoint ? 'pb-1' : 'pb-5')} >
                        KEY FEATURES
                        <div className="divider"></div>
                    </h5>
                    {(windowWidth && windowWidth < breakPoint) ? <KeyFeaturesMobileWidth windowWidth={windowWidth} /> : <KeyFeaturesFullWidth />}
                </Container>
            </Container>

            {/* Why RippleGo */}
            <Container fluid className="px-0 text-white bg"  style={{backgroundColor: '#009090', position: 'relative'}}>
                <div className="wave style2" style={{marginTop: '-1px'}}>
                    <svg className="style2" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill style2"></path>
                    </svg>
                </div>    
                <Container fluid className="my-0 container-padding text-center">
                    <h5 className="pb-1 pt-5">Why Did We Create RippleGo?</h5>
                    <div className="divider white my-4"></div>
                    <div className="mb-5 pt-1">
                        {GlobalJSON.solution.map( (info) => (
                            <div key={info.header} className="text-start">
                                <h5 className={"pb-2 " + (info.header === "Our Solution" ? 'mt-5' : 'mt-2')}>{info.header}</h5>
                                {info.subheader && 
                                    <h6 className="mb-3" style={{color: '#d4d4d4'}}>{info.subheader}</h6>
                                }
                                {info.header === "Our Solution" ? 
                                <p>RippleGo is a <u><a id="patendted" href='https://patents.google.com/patent/US20220245456A1/' target='_blank' rel="noreferrer">patented</a></u>, AI based voyage planning tool and alert system that uses predictive analytics to monitor and analyze dozens of data sources (including AIS data from Spire) to forecast changes and provide accurate, real-time notifications on current conditions and forecasted changes along your voyage (including changes in river level conditions, navigation hazards, lock status changes and bridge air gap clearances).</p>
                                :
                                    <p>{info.description}</p>
                                }
                            </div>
                        ))}
                    </div>
                    <Link 
                        className="link-button ripplego mx-auto"
                        to="/contact"
                    >
                        REQUEST A DEMO
                    </Link>
                    <br/>
                    <br/>
                </Container>
            </Container>


            <Container id="content" fluid className="px-0" style={{backgroundColor: 'white', position: "relative"}}>
                <Container fluid className="my-0 container-padding">
                <h5 className="pb-2 mt-2" >Learn More About RippleGo</h5>
                <div className="ripplego-video">
                    <iframe 
                        width="100%"
                        src="https://www.youtube.com/embed/ByC9MjPHsIs" 
                        title="Trabus Technologies RippleGo Video" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>
                    </iframe>
                </div>
                </Container>

                <Container className="text-center mb-5">
                    <h5 className="pb-1 mt-5 pt-3" >User Testimonials</h5>
                    <div className="divider my-4"></div>
                    <Row className="justify-content-around">
                        {GlobalJSON.testimonials.map( (info, key) => (
                            <Col lg={5} className="mb-4">
                                <div className="quote mt-2 ms-2">
                                    <FontAwesomeIcon icon={faQuoteRight} />
                                </div>
                                <p key={key} className="text-start" style={{margin: '-30px 0 0 45px'}}>
                                    {info}
                                </p>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>

            {/* Request A Demo */}
            <Container fluid id="box4" className="bg px-0 position-relative text-white" style={{backgroundColor: '#009090'}}>
                <div className="wave" style={{marginTop: '-1px'}}>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
                <Container fluid className="my-0 text-center container-padding">
                    <h5 className="pb-1 mt-5 pt-3" >Request A Demo</h5>
                    <div className="divider white my-4"></div>
                    <p className="pt-1" style={{whiteSpace: "pre-wrap"}}>Trabus is currently looking for barge companies who would be interested in requesting a demo for this innovative technology. Those interested in exploring this opportunity can make inquiries below.</p>
                    <p className="mb-5" style={{whiteSpace: "pre-wrap"}}>Participating companies will receive a free license for a limited trial period for each vessel testing it. Requesters of the demo will be invited to share feedback on the software, contributing to its enhancement and making it more tailored to meet your voyage needs.</p>
                    <Link 
                        className="link-button beta mx-auto mt-4"
                        style={{width: '250px'}}
                        to="/contact"
                    >
                        REQUEST A DEMO
                    </Link>
                    <br/>
                    <br/>
                    <br/>
                    <small>Already have an account? <Link className='text-white' to="https://app.ripplego.com/"><u>Log in</u>.</Link></small>
                    <br/>
                    <br/>
                </Container>
            </Container>
        </Layout>
    )
}
