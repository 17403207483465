import React, {useEffect, useState, useRef} from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import GlobalJSON from "../content/global.json"
import {Row, Col } from 'react-bootstrap';
import PrevoyageImg from "../images/prevoyage.png"
import DecklogsImg from "../images/decklogs.png"
import LouisvilleRiver from "../images/louisville.png"
import RiverLevel from "../images/river-level.png"
import RipplegoIcons from "../components/ripplegoIcons"
import closeIcon from "../images/closeIcon.png"

export default function KeyFeaturesFullWidth() {
    const refBRIDGE = useRef(null)
    const refLOCK = useRef(null)
    const refRIVER = useRef(null)
    const refMSI = useRef(null)
    const [type, setType] = useState("")
    const [hoverType, setHoverType] = useState("")

    useEffect(() => {
        function handleClickOutsideAlerts(e) {
            if(refBRIDGE.current && !refBRIDGE.current.contains(e.target) &&
                refLOCK.current && !refLOCK.current.contains(e.target) &&
                refRIVER.current && !refRIVER.current.contains(e.target) &&
                refMSI.current && !refMSI.current.contains(e.target)
            ) {
                setType("")
                setHoverType("")
            }
        }

        document.addEventListener('mousedown', handleClickOutsideAlerts)

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideAlerts)
        }

    }, [refBRIDGE, refLOCK, refRIVER, refMSI])

    const selectedType = name => {
        setType(name !== type ? name : "")
    }

    const getRef = type => {
        switch(type) {
            case "BRIDGE":
                return refBRIDGE
            case "LOCK":
                return refLOCK
            case "RIVER":
                return refRIVER
            case "MSI":
                return refMSI
            default:
                return;
        }
    }

    const popup = name => {
        switch(name) {
            case 'BRIDGE':
                return (
                    <div className={'rounded-left ' + type}>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <div style={{paddingLeft:'10px'}}><b>SHERMAN MINTON HIGHWAY &#40;I-64&#41; BRIDGE</b></div>
                            <img src={closeIcon} style={{alignSelf: 'flex-start', padding:'0 10px'}}/>
                        </div>
                        <div className={'ripplego-popup-inner ' + type}>
                            <span style={{display:'block'}}><span style={{fontWeight:'bold'}}>Clearance: </span>73.24ft</span>
                            <span style={{display:'block'}}><span style={{fontWeight:'bold'}}>Message: </span>Clearance predictions</span>
                            <span style={{display:'block'}}><span style={{fontWeight:'bold'}}>Mile: </span>Ohio River Mile 786.8</span>
                            <span style={{display:'block'}}><span style={{fontWeight:'bold'}}>ETA: </span>December 25, 2021 15:25</span>
                        </div>
                    </div>
                )
            case 'RIVER':
                return (
                    <div className={'rounded-left ' + type}>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <div style={{paddingLeft:'10px'}}><b>OHIO RIVER AT WATER TOWER AT LOUISVILLE, KY</b></div>
                            <img src={closeIcon} style={{alignSelf: 'flex-start', padding:'0 10px'}}/>
                        </div>
                        <div className={'ripplego-popup-inner ' + type}>
                            <span style={{display:'block'}}><span style={{fontWeight:'bold'}}>Clearance: </span>-3.05 ft</span>
                            <span style={{display:'block'}}><span style={{fontWeight:'bold'}}>Message: </span>River Level Predictions</span>
                            <span style={{display:'block'}}><span style={{fontWeight:'bold'}}>Mile: </span>Ohio River Mile 601</span>
                            <span style={{display:'block'}}><span style={{fontWeight:'bold'}}>ETA: </span>December 30, 2021 07:10</span>
                        </div>
                    </div>
                )
            case 'LOCK': 
                return (
                    <div className={'rounded-left ' + type}>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <div style={{paddingLeft:'10px'}}><b>MCALPINE LOCKS AND DAM</b></div>
                            <img src={closeIcon} style={{alignSelf: 'flex-start', padding:'0 10px'}}/>
                        </div>
                        <div className={'ripplego-popup-inner ' + type}>
                            <span><b>Current status: </b>No current lock status data</span><br/>
                            <span><b>Mile: </b>Ohio River Mile 607</span><br/>
                            <span><b>ETA: </b>January 05, 2022 10:13</span>
                        </div>
                    </div>
                )
            case 'MSI': 
                return (
                    <div className={'rounded-left ' + type}>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <div style={{paddingLeft:'10px'}}><b>LNM Notice: BRIDGE MAINTENANCE at MILE 608.6</b></div>
                            <img src={closeIcon} style={{alignSelf: 'flex-start', padding:'0 10px'}}/>
                        </div>
                        <div className={'ripplego-popup-inner ' + type}>
                            <span><span style={{fontWeight:'bold'}}>Message: </span>Sherman Minton Bridge; Cleaning and painting of the bridge will continue from 7:00 a.m. until 6:00 p.m., Monday through...</span><br/>
                            <span><span style={{fontWeight:'bold'}}>Mile: </span>Ohio River MILE 608.6</span><br/>
                            <span><span style={{fontWeight:'bold'}}>ETA: </span>December 25, 2021 15:25</span>
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    return (
        <div>
            {GlobalJSON.features.map((item, key) => (
                <AnimationOnScroll animateIn="animate__fadeIn" duration={1.5} animateOnce={true} key={key}>
                    {key % 2 === 0 ?
                        <Row className='justify-content-between text-start mt-5' style={{marginTop: (key === 0 ? '3rem' : '10rem')}}>
                            <Col lg={5}>
                                <ul style={{fontSize: 18}}>
                                    <h5 style={{color: '#009090'}}><b>{item.header}</b></h5>
                                    {item.list.map((li) => (
                                        <li key={li} style={{fontWeight: 'normal', marginTop: 10}}>{li}</li>
                                    ))}
                                </ul>
                            </Col>
                            {item.shortName === "predictive" ?
                                <Col lg={6} className="text-left">
                                    <img className='river-level' src={RiverLevel} alt="River Level" />
                                </Col>
                            :
                                // Alert System
                                <Col lg={6} className="text-left">
                                    <div className='key-feature-alert'>
                                        <img className='river-map' src={LouisvilleRiver} alt="Memphis"/>
                                        <small><i>click on alert icons to interact</i></small>
                                        {GlobalJSON.alerts.map((alert, key) => (
                                            <div key={key} ref={getRef(alert.type)} className={"ripplego-alerts " + alert.type}>
                                                <RipplegoIcons 
                                                    type={alert.type} 
                                                    color={hoverType === alert.type ? alert.darkerColor : alert.color} 
                                                    clicked={type === alert.type ? true : false} 
                                                    onClick={() => selectedType(alert.type)} 
                                                    onMouseEnter={() => setHoverType(alert.type)} 
                                                    onMouseLeave={() => setHoverType("")}
                                                />
                                            </div>
                                        ))}
                                        {type !== "" &&
                                            <div className={"ripplego-popup " + type}>
                                                {popup(type)}
                                            </div>
                                        }
                                    </div>
                                </Col>
                            }
                        </Row>
                        :
                        <Row className='justify-content-between text-start py-5' style={{marginTop: '10rem', marginBottom: '5rem'}}>
                            <Col lg={6} className="text-left pe-5 mb-4">
                                <img className='voyage-img decklogs' src={DecklogsImg} alt="Decklogs"/>
                                <img className='voyage-img prevoyage' src={PrevoyageImg} alt="Prevoyage"/>
                            </Col>
                            <Col lg={5} className="mt-3">
                                <ul style={{fontSize: 18}}>
                                    <h5 style={{color: '#009090'}}><b>{item.header}</b></h5>
                                    {item.list.map((li, index) => (
                                        <li key={li} style={{fontWeight: 'normal', marginTop: 10}}>{li}</li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                    }
                </AnimationOnScroll>
            ))}
        </div>
    )
}
